<template>
  <div>
    <Portal v-if="!needsOnboarding" to="subnav">
      <CNavSubItem to="overview">{{ $t('overview') }}</CNavSubItem>
      <CNavSubItem to="orders">{{ $tc('allOrders') }}</CNavSubItem>
    </Portal>
    <router-view />
    <Portal to="overlays">
      <OrderDetailModal
        :show="orderDetail"
        :order-id="orderId"
        @close="onDetailModalClose"
      >
        {{ orderId }}
      </OrderDetailModal>
    </Portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { THIS_MERCHANT, WINDOW_HEIGHT, NEEDS_ONBOARDING } from '../../../store/gettersTypes';
import OrderDetailModal from '@/ui/components/Merchant/Orders/DetailModal.vue';

export default {
  components: {
    OrderDetailModal,
  },

  data: () => ({
    orderDetail: false,
    orderId: null,
  }),

  computed: {
    ...mapGetters([THIS_MERCHANT, WINDOW_HEIGHT, NEEDS_ONBOARDING]),
  },

  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        if (newVal.query.order) {
          this.orderDetail = true;
          this.orderId = Number(newVal.query.order);
        } else {
          this.orderDetail = false;
          setTimeout(() => {
            this.orderId = null;
          }, 1000);
        }
      },
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
    runAction(action) {
      this.$refs.actions.open(action);
    },
    onDetailModalClose() {
      this.$router.replace({
        query: {},
      });
    },
  },
};
</script>
